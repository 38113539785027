import React, { useState } from "react"
import { Section } from "./Section"
import { Column } from "./Columns"
import { Link } from "gatsby"
import GatsbyImage from "gatsby-image"

export interface FeaturedImage {
  childImageSharp: any
}

export interface Frontmatter {
  end: number,
  start?: number,
  skills: string[],
  title: string,
  company: string,
  role: string,
  featuredImage: FeaturedImage
}

export interface Parent {
  name: string;
}

export interface Project {
  frontmatter: Frontmatter
  parent: Parent
}

export interface Props {
  projects: Project[]
}

export const Projects: React.FC<Props> = ({ projects }) => {
  const [selected, setSelected] = useState<string>()
  const show = (target: string) => () => {
    setSelected(prev => target === prev ? undefined : target)
  }
  return (
    <Section id="projects" className={selected}>
      <div className="centered"><h2>What?</h2></div>
      <div className="skills columns hidden-on-mobile">
        <div className="column" onClick={show("frontend")}>
          <div className="frontend skill">
            <span>Frontend</span>
          </div>
        </div>
        <div className="column" onClick={show("backend")}>
          <div className="backend skill">
            <span>Backend</span>
          </div>
        </div>
        <div className="column" onClick={show("devops")}>
          <div className="devops skill">
            <span>DevOps</span>
          </div>
        </div>
        <div className="column" onClick={show("testing")}>
          <div className="testing skill">
            <span>Testing</span>
          </div>
        </div>
      </div>
      <div className="show-frontend project-list three columns">
        {projects.map(({ frontmatter: { title, skills, role, featuredImage, start, end, company }, parent: { name } }) => (
          <Column key={name}>
            <Link to={`/projects/${name}`} className={`${skills.join(" ")} project`}>
              <header>
                <h3>{title}</h3>
                <span className="sub">{role}</span>
                <span className="sub">{company} - {start && `${start} to`} {end}</span>
              </header>
              <GatsbyImage {...featuredImage.childImageSharp}/>
            </Link>
          </Column>
        ))}
      </div>
    </Section>
  )
}
