import React from "react"
import { Section } from "../components/Section"
import { Hero } from "../components/Hero"
import { Layout } from "../components/Layout"
import { Column, Columns } from "../components/Columns"
import { Notification } from "../components/Notification"
import { Projects } from "../components/Projects"
import { graphql } from "gatsby"
import featureImage from "../images/fi_home.png"
import { Crumb } from "../components/Breadscrumbs"

export const HomeCrumb: Crumb = {
  name: "Home",
  page: "/",
  icon: "fa-home"
}

export default ({ data: { projects: { nodes: projects } } }) => (
  <Layout header={<Hero/>}
          seo={{
            title: "Fullstack Developer",
            description: "Get in touch to learn how I will help you realize your impactful software product.",
            path: "/",
            image: featureImage,
            ogTags: {
              "og:type": "website"
            }
          }}
  >
    <Section id="about">
      <div className="centered"><h2>Who?</h2></div>
      <div className="avatar"/>
      <Columns>
        <Column>
          <div className="feature">
            <div className="badge">
              <i className="fas fa-balance-scale"/>
            </div>
            <h3>End-to-End</h3>
            <p>I work on software projects at every stage. From inception to deployment.</p>
          </div>
        </Column>
        <Column>
          <div className="feature">
            <div className="badge">
              <i className="fas fa-users"/>
            </div>
            <h3>Team Oriented</h3>
            <p>I love to build teams and work together to be as effective as possible.</p>
          </div>
        </Column>
        <Column>
          <div className="feature">
            <div className="badge">
              <i className="fas fa-robot"/>
            </div>
            <h3>Automation Fanatic</h3>
            <p>There is no point in doing repetitive tasks. Embrace DevOps and automate everything.</p>
          </div>
        </Column>
        <Column>
          <div className="feature">
            <div className="badge">
              <i className="fas fa-user-plus"/>
            </div>
            <h3>User Focus</h3>
            <p>The most important thing in a product is the user. If something is not useful to them, it is not
              useful.</p>
          </div>
        </Column>
      </Columns>
    </Section>
    <Projects projects={projects}/>
    {/*<Section id="contact">*/}
    {/*  <div className="centered"><h2>When?</h2></div>*/}
    {/*  <Notification>*/}
    {/*    I am currently available for freelance assignments. Contact me to learn more.*/}
    {/*  </Notification>*/}
    {/*  <form method="POST" name="contact" data-netlify="true" data-netlify-honeypot="bot-field">*/}
    {/*    <input type="hidden" name="form-name" value="contact"/>*/}
    {/*    <p className="hidden">*/}
    {/*      <label>Don’t fill this out if you're human: <input name="bot-field"/></label>*/}
    {/*    </p>*/}
    {/*    <label htmlFor="name">Name</label>*/}
    {/*    <input id="name" name="name"/>*/}
    {/*    <label htmlFor="email">Email</label>*/}
    {/*    <input id="email" name="email" type="email"/>*/}
    {/*    <label htmlFor="message">Message</label>*/}
    {/*    <textarea id="message" name="message" rows={8}/>*/}
    {/*    <input type="submit" value="Send"/>*/}
    {/*  </form>*/}
    {/*</Section>*/}
  </Layout>
)

export const pageQuery = graphql`
{
  projects: allMarkdownRemark(sort: {fields: [frontmatter___end, frontmatter___title], order: DESC}, filter: {fields: {sourceName: {eq: "projects"}}}) {
    nodes {
      frontmatter {
        end
        role
        skills
        start
        title
        company
        featuredImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          } 
        }
      }
      parent {
        ... on File {
          name
        }
      }
    }
  }
}
`
