import React, { useEffect, useRef, useState } from "react"
import { Link } from "gatsby"

export const Hero: React.FC = ({}) => {
  const [height, setHeight] = useState('100vh');

  useEffect(() => {
    const syncHeroHeight = () => {
      setHeight(`${window.innerHeight}px`);
    }
    syncHeroHeight()
    window.addEventListener("resize", syncHeroHeight)

    return () => {
      window.removeEventListener("resize", syncHeroHeight)
    }
  }, [])
  return (
    <div className="hero" style={{height}}>
      <div className="background"/>
      <div className="hero-top">
        <h1>Hi, I'm <span className="accent">Thomas Biesaart</span>.</h1>
        <span>I'm a full-stack developer.</span>
        <span className="mission">
            I work with startups and scale-ups to realize <strong>creative</strong> and <strong>impactful</strong> products.
        </span>
      </div>
      <div className="hero-bottom">
        <Link to="/#projects">
          <i className="fas fa-arrow-down"/>
          <span>Check out my work</span>
          <i className="fas fa-arrow-down"/>
        </Link>
      </div>
    </div>
  )
}
